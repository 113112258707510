import React from 'react';
import { PagesContext } from '../../../contexts/PagesContext';
import { AppWidgetProps } from '../../../types/common';
import { PaywallWidget } from './PaywallWidget';

export default function (props: AppWidgetProps) {
  return (
    <PagesContext locale={props.locale}>
      <PaywallWidget {...props} />
    </PagesContext>
  );
}
